import React from 'react';
import styled from 'styled-components';
import { PodcastGrid } from '../../SharedSections';
import { Link } from '../../../Gui/Links';
import { Color, Fonts } from '../../../../styles';

const Wrapper = styled.section`
  margin-top: 240px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > h1 {
    font-size: 2rem;
    line-height: calc(25 / 20);
    letter-spacing: calc(-0.68em / 20);
    margin-bottom: 40px;
  }

  a {
    font-size: 1.4rem;
    color: ${Color.BLACK};
    ${Fonts.POPPINS};
    font-weight: 500;
  }
`;

const PodcastsSection = ({ podcastData }) => {
  const podcasts = podcastData.slice(0, 6);
  return (
    <Wrapper>
      <HeaderContainer>
        <h1>Podcasts</h1>
        <Link url={'/podcasts'}>View all</Link>
      </HeaderContainer>
      <PodcastGrid podcasts={podcasts} />
    </Wrapper>
  );
};

export default PodcastsSection;
