import React from 'react';
import styled from 'styled-components';
import { globalH2Default, mediaQueries } from '../../../styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Heading = styled.h1`
  ${globalH2Default};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .heading-image {
    flex: 0 0 auto;
    margin-left: 24px;
  }

  ${mediaQueries('md')`
      font-size: 7.48rem;
      line-height: calc(65/75);
      letter-spacing: calc(-0.68em/75);
    `};
`;

const HeadingWithImage = ({ headingLevel, heading, image, ...rest }) => {
  const headingImage = getImage(image);

  return (
    <Heading as={headingLevel} {...rest}>
      {heading}
      <GatsbyImage className={'heading-image'} alt={''} image={headingImage} />
    </Heading>
  );
};

export default HeadingWithImage;
