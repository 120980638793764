import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage as gatsbyGetImage } from 'gatsby-plugin-image';

import { CardGrid } from '../../Gui/Layout';
import { DefaultCard } from '../../Gui/Cards';
import { Fonts, mediaQueries } from '../../../styles';
import { getImage } from '../../../utils';
import { TwitterIcon } from '../../Svg';

const TwitterCardWrapper = styled(DefaultCard)`
  text-align: left;
  padding: 24px 32px;
  min-height: 16rem;

  ${mediaQueries('md')`
    padding: 40px 28px 22px;
    min-height: 26.4rem;
  `};
`;

const Content = styled.div`
  position: relative;
  height: 100%;

  p {
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    ${mediaQueries('md')`
      max-width: unset;
      -webkit-line-clamp: 4;
    `};
  }
`;

const BottomRow = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  margin-top: 24px;

  .gatsby-image-wrapper {
    max-width: 40px;
  }

  > *:nth-child(2) {
    margin-left: 16px;
    margin-right: auto;
  }

  ${mediaQueries('md')`
    max-width: 305px;
    justify-content: space-between;
    
    > *:nth-child(2){
      margin: 0;
    }
    margin-top: unset;
  `};
`;

const NameAndDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .name {
    ${Fonts.POPPINS};
    font-size: 1.6rem;
    line-height: calc(18.6 / 16);
    font-weight: 700;
  }

  .date {
    ${Fonts.POPPINS};
    font-size: 1.2rem;
    line-height: calc(18.6 / 12);
    font-weight: 400;
    color: #6a7187;
  }
`;

const TwitterFeed = ({ tweets }) => {
  const {
    datoCmsMikesWebsite: { heroAssets: assets },
  } = useStaticQuery(graphql`
    query {
      datoCmsMikesWebsite {
        heroAssets {
          size
          width
          height
          path
          format
          isImage
          notes
          author
          copyright
          filename
          basename
          exifInfo
          mimeType
          blurhash
          originalId
          url
          createdAt
          gatsbyImageData
          alt
          title
          customData
        }
      }
    }
  `);

  return (
    <CardGrid>
      {tweets.map(({ node: { text, tweetId, createdAt } }, index) => {
        return (
          <TwitterCardWrapper url={`https://twitter.com/mcagney/status/${tweetId}`} key={`TweetCard-${index}`}>
            <Content>
              <p>{text}</p>
              <BottomRow>
                <GatsbyImage alt={''} image={gatsbyGetImage(getImage(assets, 'mike-cagney-twitter-avi'))} />
                <NameAndDate>
                  <p className={'name'}>Mike Cagney</p>
                  <p className={'date'}>{createdAt}</p>
                </NameAndDate>
                {TwitterIcon}
              </BottomRow>
            </Content>
          </TwitterCardWrapper>
        );
      })}
    </CardGrid>
  );
};

export default TwitterFeed;
