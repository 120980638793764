import React from 'react';
import styled from 'styled-components';

import { Layout, Width } from '../../../styles';

const Wrapper = styled.span`
  height: 0;
  width: 0;
  display: block;
  position: relative;
  top: -82px;

  @media print, screen and (min-width: ${Width.BREAK_MOBILE_NAV}px) {
    top: -${Layout.NAV_HEIGHT_DESKTOP}px;
  }
`;

export const Anchor = ({ id }) => <Wrapper id={id} />;

export default Anchor;
