import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2,
  h3,
  h4 {
    & + p {
      margin-top: 24px;
    }
  }
`;

const Text = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default Text;
