import React from 'react';

import { CardGrid } from '../../Gui/Layout';
import { MediaCard } from '../Card';
import { Link } from '../../Gui/Links';
import styled from 'styled-components';
import {Color, Fonts, mediaQueries} from '../../../styles';

const Wrapper = styled.section`
    padding-top: 100px;
    ${mediaQueries('md')`
      padding-top: 120px;
    `};
    ${mediaQueries('xl')`
      padding-top: 115px;
    `};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > h1 {
    font-size: 2rem;
    line-height: calc(25 / 20);
    letter-spacing: calc(-0.68em / 20);
    margin-bottom: 40px;
  }

  a {
    font-size: 1.4rem;
    color: ${Color.BLACK};
    ${Fonts.POPPINS};
    font-weight: 500;
  }
`;

const HomepageSection = ({ title, viewAllUrl, cardIcon, mediaLinkData, cardComponent = MediaCard }) => {
  const Card = cardComponent;
  return (
    <Wrapper>
      <HeaderContainer>
        <h1>{title}</h1>
        <Link url={viewAllUrl}>View all</Link>
      </HeaderContainer>
      <CardGrid>
        {mediaLinkData.slice(0, 6).map(cardData => {
          return <Card {...cardData} icon={cardIcon} />;
        })}
      </CardGrid>
    </Wrapper>
  );
};

export default HomepageSection;
