import React from 'react';
import styled from 'styled-components';
import { TwitterFeed } from '../../SharedSections';
import { Link } from '../../../Gui/Links';
import { Color, Fonts, mediaQueries } from '../../../../styles';
import Anchor from '../../../Gui/Anchor';

const Wrapper = styled.section`
    padding-top: 100px;
    ${mediaQueries('md')`
      padding-top: 120px;
    `};
    ${mediaQueries('xl')`
      padding-top: 115px;
    `};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > h1 {
    font-size: 2rem;
    line-height: calc(25 / 20);
    letter-spacing: calc(-0.68em / 20);
    margin-bottom: 40px;
  }

  a {
    font-size: 1.4rem;
    color: ${Color.BLACK};
    ${Fonts.POPPINS};
    font-weight: 500;
  }
`;

const TweetsSection = ({ tweetData }) => (
  <Wrapper>
    <Anchor id={'feed'} />
    <HeaderContainer>
      <h1>Feed</h1>
      <Link url={'https://www.twitter.com/mcagney'}>View Twitter</Link>
    </HeaderContainer>
    <TwitterFeed tweets={tweetData} />
  </Wrapper>
);

export default TweetsSection;
