import React from 'react';
import styled from 'styled-components';
import { StructuredText } from 'react-datocms';

import { BaseSection } from '../../../Gui/Layout';
import { Fonts, mediaQueries } from '../../../../styles';
import BaseText from '../../Text';
import { HeadingWithImage } from '../../Headings';
import { getImage } from '../../../../utils';
import { GatsbyImage, getImage as gatsbyGetImage } from 'gatsby-plugin-image';
import { TwitterIcon, LinkedInIcon, FigureLogo, FigureMarketsLogo, ProvenanceLogo } from '../../../Svg';
import { Link } from '../../../Gui/Links';

const Section = styled(BaseSection)`
  position: relative;
  max-width: 50rem;

  .desktop-hero-image {
    display: none;
    position: absolute;
    width: 721px;
    bottom: 0;
    right: -104px;

    ${mediaQueries('md')`
      display: block;
      bottom: -110px;
    `};

    ${mediaQueries('xl')`
      width: 821px;
      left: -29px;
      right: unset;
      bottom: -182px;
    `};
  }

  ${mediaQueries('md')`
    height: calc(113.3rem - 155px);
    max-width: 90.9rem;
    margin-bottom: 183px;
  `};

  ${mediaQueries('xl')`
    height: calc(83.6rem - 213px);
    max-width: unset;
  `};
`;

const Text = styled(BaseText)`
  strong {
    ${Fonts.POPPINS};
  }

  //selects bold text underneath main heading
  h2 {
    margin-top: 32px;
    ${Fonts.GOTHIC};
    font-size: 1.5rem;
    line-height: calc(27/15);
    letter-spacing: calc(0.26em/15);

    ${mediaQueries('md')`
      width: 72%;
    `};
  }

  .heading {
    .heading-image {
      max-width: 96px;
      ${mediaQueries('md')`
        display: none;
      `};
    }
  }
`;

const SocialMediaLinks = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    display: block;

    &:first-of-type {
      margin-right: 29px;
    }
  }
`;

const CompanyImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 84px;

  > *:not(:last-child) {
    margin-bottom: 42px;
  }

  .figure-logo {
    width: 7rem;

    ${mediaQueries('md')`
      width: 9.3rem;
    `};
  }

  .provenance-blockchain-logo {
    width: 8.8rem;

    ${mediaQueries('md')`
      width: 11.8rem;
    `};
  }

  .sofi-logo {
    width: 5.4rem;

    ${mediaQueries('md')`
      width: 7.2rem;
    `};
  }

  ${mediaQueries('md')`
    > *:not(:last-child) {
      margin-bottom: 56px;
    }
  `};

  ${mediaQueries('xl')`
    margin-top: 110px;
    flex-direction: row;
    align-items: center;
    
    > *:not(:last-child) {
      margin-right: 42px;
      margin-bottom: 0;
    }
  `};
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  
  padding-top: 173px;

  ${mediaQueries('md')`
    max-width: 45rem;
    padding-top: 120px;
  `};

  ${mediaQueries('xl')`
    margin-left: auto;
    padding-top: 185px;
  `};
`;

const Hero = ({ heroCopy, heroAssets }) => {
  return (
    <Section>
      <Content>
        <Text>
          <StructuredText
            data={heroCopy}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case 'DatoCmsHeadingWithImage':
                  return <HeadingWithImage className={'heading'} {...record} />;
                default:
                  return null;
              }
            }}
          />
        </Text>
        <SocialMediaLinks>
          <Link url={'https://twitter.com/mcagney'}>{TwitterIcon}</Link>
          <Link url={'https://www.linkedin.com/in/mikecagney/'}>{LinkedInIcon}</Link>
        </SocialMediaLinks>
        <CompanyImages>
          {FigureMarketsLogo}
          {FigureLogo}
          {ProvenanceLogo}
          <GatsbyImage
            alt={'SoFi'}
            image={gatsbyGetImage(getImage(heroAssets, 'sofi-logo'))}
            className={'sofi-logo'}
            objectFit={'contain'}
          />
        </CompanyImages>
      </Content>
      <GatsbyImage alt={''} image={gatsbyGetImage(getImage(heroAssets, 'mike-cagney-hero-desktop'))} className={'desktop-hero-image'} />
    </Section>
  );
};

export default Hero;
