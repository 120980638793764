import React from 'react';
import styled from 'styled-components';
import { WebsiteDefaultPage } from '../components/Page';
import { Color, mediaQueries } from '../styles';
import { Hero, TweetsSection } from '../components/Content/PageSpecific/homepage';
import { graphql } from 'gatsby';
import { useIntersectionObserver } from '../hooks';
import ScrollProgressBar from '../components/Gui/ScrollProgressBar';
import { HomepageSection } from '../components/Content/SharedSections';
import { PodcastIcon, VideosIcon } from '../components/Svg';
import { ArticleCard } from '../components/Content/Card';

const CardSectionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;

  > *:last-child {
    padding-bottom: 240px;
  }

  ${mediaQueries('md')`
    max-width: unset;
  `};
`;

const Home = ({
  data: {
    datoCmsMikesWebsite: { heroCopy, heroAssets, articles, podcasts, videos },
    allTweet: { edges: tweets },
  },
}) => {
  const [elRef, isIntersecting] = useIntersectionObserver({ threshold: 0.25 });
  return (
    <WebsiteDefaultPage bodyBackground={Color.GREY_ONE}>
      <Hero heroCopy={heroCopy} heroAssets={heroAssets} />
      <CardSectionsWrapper ref={elRef}>
        <TweetsSection tweetData={tweets} />
        <HomepageSection title={'Articles'} viewAllUrl={'/articles'} mediaLinkData={articles} cardComponent={ArticleCard} />
        <HomepageSection title={'Podcasts'} viewAllUrl={'/podcasts'} cardIcon={PodcastIcon} mediaLinkData={podcasts} />
        <HomepageSection title={'Videos'} viewAllUrl={'/videos'} cardIcon={VideosIcon} mediaLinkData={videos} />
      </CardSectionsWrapper>
      <ScrollProgressBar cardSectionsAreIntersecting={isIntersecting} />
    </WebsiteDefaultPage>
  );
};

export default Home;

export const query = graphql`
  query HomePageQuery {
    datoCmsMikesWebsite {
      heroCopy {
        value
        blocks {
          __typename
          id: originalId
          heading
          headingLevel
          image {
            size
            width
            height
            path
            format
            isImage
            notes
            author
            copyright
            filename
            basename
            exifInfo
            mimeType
            blurhash
            originalId
            url
            createdAt
            gatsbyImageData
            alt
            title
            customData
          }
        }
      }
      heroAssets {
        size
        width
        height
        path
        format
        isImage
        notes
        author
        copyright
        filename
        basename
        exifInfo
        mimeType
        blurhash
        originalId
        url
        createdAt
        gatsbyImageData
        alt
        title
        customData
      }
      articles {
        ...MediaLink
      }
      podcasts {
        ...MediaLink
      }
      videos {
        ...MediaLink
      }
    }
    allTweet(limit: 6) {
      edges {
        node {
          text
          tweetId
          createdAt: created_at(formatString: "MMM DD")
        }
      }
    }
  }
`;
