import React from 'react';
import styled from 'styled-components';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Color, mediaQueries, Transition } from '../../../styles';
import { Link } from '../Links';
import { LinkedInIcon, TwitterIcon } from '../../Svg';

const Wrapper = styled.div`
  display: none;
  position: fixed;
  right: 40px;
  top: 204px;

  ${mediaQueries('md')`    
    @media print, screen and (min-height: 500px){
      display: block;
    }
  `};

  ${mediaQueries('xl')`
    right: unset;
    
    @media print, screen and (min-height: 820px){
      display: block;
    }
  `};
`;

const ProgressBarContainer = styled.div`
  height: 360px;
  width: 3px;
  background-color: #c4c4c4;

  .progress-bar {
    width: 100%;
    position: relative;
    top: 0;
    height: 72px;
    background-color: ${Color.NEUTRAL_700};
  }
`;

const SocialMediaLinks = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  left: -50%;
  margin-top: 115px;

  a {
    &:first-of-type {
      margin-bottom: 30px;
    }
  }
  transition: opacity ${Transition.DEFAULT};
  opacity: ${({ active }) => (active ? 1 : 0)};

  ${mediaQueries('xl')`
    display: flex;
  `};
`;

const ScrollProgressBar = ({ cardSectionsAreIntersecting }) => {
  const { scrollYProgress } = useViewportScroll();
  const translateY = useTransform(scrollYProgress, [0, 1], ['0%', '400%']);

  return (
    <Wrapper>
      <ProgressBarContainer>
        <motion.div className={'progress-bar'} style={{ translateY }} />
      </ProgressBarContainer>
      <SocialMediaLinks active={cardSectionsAreIntersecting}>
        <Link url={'https://twitter.com/mcagney'}>{TwitterIcon}</Link>
        <Link url={'https://www.linkedin.com/in/mikecagney/'}>{LinkedInIcon}</Link>
      </SocialMediaLinks>
    </Wrapper>
  );
};

export default ScrollProgressBar;
